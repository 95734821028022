import { Box, Typography } from '@mui/material'
import React from 'react'

const TopupValidation = () => {
  return (
    <Box>
        <Typography sx={{ fontFamily:'Poppins', fontWeight:500, fontSize:"1.2rem", mt:10 }} > Accessed topup validation route </Typography>
    </Box>


  )
}

export default TopupValidation