import React from 'react'
import { useLocation } from 'react-router-dom'

const TransFetchDetails = () => {

  const location = useLocation();
  const userData = location.state.data
  console.log("user data : ",userData);

  return (
    <div>TransFetchDetails</div>
  )
}

export default TransFetchDetails