import { Box, Button, Divider, FormControl, IconButton, InputLabel, MenuItem, Pagination, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import React from 'react'
import Header from '../components/Header'
import reportFile from "../images/reportFile.jpg";
import fileIcon from "../images/fileIcon.webp";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import eezib2 from "../images/eezib.png"
import moment from 'moment';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { HandleApi } from '../HandleApi';
import reportCard from "../images/reportCard.png";
import jsPDF from 'jspdf';
import DownloadIcon from '@mui/icons-material/Download';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SnackBarSuccess from '../components/SnackBarSuccess';
import imagePng from "../images/imagePng.png";
import * as XLSX from "xlsx";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

const Report = () => {

    const  [ startDate , setStartDate ] = React.useState("");
    const  [ endDate , setEndDate ] = React.useState("");
    const [ reportType , setReportType ] = React.useState();
    const [age, setAge] = React.useState('');
    const [ reportData , setRepotData ] = React.useState();
    const [ reportHeader, setReportHeader ] = React.useState();
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [ snackMessage , setSnackMessage ] = React.useState("");
    const [ snackOpen , setSnackOpen ] = React.useState(false);
    const [ snackSeverity , setSnackSeverity ] = React.useState("");
    
    const authAccess = Cookies.get("demoCAuth");

    const startIndex = (page -1)* rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const slicedData = reportData?.slice(startIndex, endIndex);

    console.log("sliced data : ",slicedData)

    console.log("Age : ",age);
    
    const handleChange = (event) => {
        console.log(event.target.value)
      setAge(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    

    const handleReport = async() => {
        try{
            const url = process.env.REACT_APP_UAPI_URL;
            const apiUrl = `${url}/api/b2cReportType`;
         
            const json = await HandleApi({"apiUrl":apiUrl, "authAccess":authAccess });
    
            console.log("json data : ",json);
    
            if(json){
                if(json.statuscode === 200){
                    setReportType(json.data.report_type);
                    setReportHeader(json.data.header);
                    window.scroll(0,250)
                }
            }
        }catch(err){
            console.log(err);
        }

    }

    React.useEffect(() => {
        handleReport();
    },[])


    const handleImageData = (data) => {
      console.log("image data : ",data.image_url)
    }

    
    const getReportData = async() => {
      
      const url = process.env.REACT_APP_UAPI_URL;
      const apiUrl = `${url}/api/getReport`;
      const method = "POST";
        const body =  {
            "id":age,
            "from":startDate,
            "to":endDate
          }
          
          const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, "bodyData":body })
          console.log(json);
          
          if(json){
            if(json.statuscode === 200){
              window.scrollBy(0,350);
              setRepotData(json.data.report_data);
              const headerData = Object.values(json.data.header);
              console.log("header : ",headerData );
              setReportHeader(json.data.header);
            } 
          }
          
        }

        const handelSnackClose = () => {
          setSnackOpen(false);
        }

        const generatePDF = () => {
          if (reportData?.length === 0) {
            alert("No data available to generate PDF");
            return;
          }
        
          const pdf = new jsPDF();
          const keys = reportHeader;
        
          // Set initial x and y position
          let xPos = 10;
          let yPos = 10;
        
          // Set cell width and height
          const cellWidth = 30;
          const minHeight = 8;
        
          // Set font size
          const fontSize = 5;
          pdf.setFontSize(fontSize);
        
          // Add headers
          keys.forEach((key, index) => {
            pdf.rect(xPos, yPos, cellWidth, minHeight, 'S');
            pdf.text(xPos + 1, yPos + 5, key);
            xPos += cellWidth;
          });
        
          // Reset x position and increment y position
          xPos = 10;
          yPos += minHeight;
        
          // Add data
          reportData?.forEach(data => {
            keys.forEach((key, index) => {
              const value = data[key] || '';
              const valueLines = pdf.splitTextToSize(value.toString(), cellWidth - 2);
              const cellHeight = Math.max(minHeight, valueLines.length * fontSize / 2); // Adjust for font size
              pdf.text(xPos + 1, yPos + 5, valueLines);
              pdf.rect(xPos, yPos, cellWidth, cellHeight, 'S');
              xPos += cellWidth;
            });
            xPos = 10;
            yPos += minHeight;
          });
        
          pdf.save('download.pdf');
        };



        const generateExcel = () => {
          if ( reportData.length === 0 || reportData.length === undefined ) {
            alert("No data available to generate Excel");
            return;
          }
        
          // Create a new workbook
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(reportData);
        
          // Add the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        
          // Write the workbook to a file
          XLSX.writeFile(workbook, 'download.xlsx');
        
          alert("Excel file generated successfully");
        };
        
        const handleMailData = async(data) => {

          const apiUrl = data.mailUrl;
          const method = "POST";
            const body =  {
                "id":data?.card_id
              }
              
              const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, "bodyData":body })
              console.log(json);

              if(json){
                if(json.statuscode === 200){
                  setSnackOpen(true);
                  setSnackMessage(json.message);
                  setSnackSeverity("success");
          
                }
                if(json.status === "error"){
                  setSnackOpen(true);
                  setSnackMessage(json.message);
                  setSnackSeverity("error");
                }
              }

        }

        console.log( "report data length : ",reportData?.length)


    return (
    <Box className="topupContainer" sx={{ minHeight:'100vh' }} >
        <Header />
        <IconButton>
        </IconButton>
        <Box sx={{ display:'flex', alignItems:'center', flexDirection:'column' }} >
        <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', mt:{lg:10, sm:10 , sm:5, xs:3 }, width:'100%', flexDirection:{lg:"row", md:"row", sm:"column", xs:"column"  } }} >

              <Box component="img" src={reportCard} sx={{ width:{lg:"35rem", md:"30rem", sm:"30rem", xs:"23rem" } }} />
            <Box className='nepo' sx={{  display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', padding:"1rem 2rem", marginTop:{lg:0 , md:0 , sm:5, xs:5} }} >
                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:1 }} >

                    <Box component="img" src={eezib2} sx={{ width:"6rem" }} />

                    <FormControl fullWidth sx={{ marginTop:"1rem" }} >
                    <InputLabel sx={{ fontFamily:'Poppins' }}  id="demo-simple-select-label"> Report Type</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Report Type"
                    onChange={handleChange}
                    sx={{ fontFamily:'Poppins' }}
                    >
                        {
                            reportType?.map((data, index) => (
                                <MenuItem sx={{ fontFamily:'Poppins' }} key={index} value={data.id} >{data.name}</MenuItem>
                            ))
                        }

                    </Select>
                </FormControl>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker disableFuture={true} defaultValue={ dayjs(startDate)} error={false}  onChange={(e) => setStartDate(moment(e?.$d).format("YYYY-MM-DD"))}  format="YYYY-MM-DD" label="Select Start Date" sx={{
                        '& .MuiInputLabel-root': {
                        fontFamily: 'Poppins',
                        },
                    }} />
                    </DemoContainer>
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker disableFuture={true} defaultValue={ dayjs(endDate)} onChange={(e) => setEndDate(moment(e?.$d).format("YYYY-MM-DD"))}  format="YYYY-MM-DD" label="Select End Date" sx={{
                        '& .MuiInputLabel-root': {
                        fontFamily: 'Poppins',
                        },
                    }} />
                    </DemoContainer>
                    </LocalizationProvider>

                    </Box>
                    

                    <Button variant="contained" disabled={ !age || !startDate || !endDate } onClick={() => getReportData()} fullWidth sx={{ fontFamily:'poppins', fontWeight:400, mt:2 }} >submit</Button>
            </Box>
            
            </Box>
            {
              reportData?.length > 1 ?  
        <TableContainer elevation={16} component={Paper} sx={{ marginTop:{lg:0 , md:0 , sm:5, xs:5} , width:"95%", mb:'5rem', mt:{md:'2.5rem' , lg:0, sm:"2.5rem", xs:"2rem" } }}>
            
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor:'#42a5f5' }} >
                        {reportHeader?.map((header, index) => (
                          <TableCell align='center' sx={{ color:"white", fontFamily:"Poppins", textTransform:"capitalize", fontSize:"0.7rem" }} key={index}>{header}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {slicedData?.map((row, rowIndex) => (
        <TableRow className="tableRow" key={rowIndex}>
          {reportHeader?.map((header, headerIndex) => (
            <TableCell  sx={{ fontFamily:"Poppins", color:"black", fontWeight:400, fontSize:"0.8rem", textAlign:'center' }} key={headerIndex}>
              {header === 'mailUrl' ? (
                <MailOutlineIcon sx={{ cursor:'pointer' }} onClick={() => handleMailData(row)  }  />
                // <a href={row[header]} target="_blank" rel="noopener noreferrer">
                //   {row[header]}
                // </a>
              ) :   header === 'image_url' ?  
              <Box component="img" onClick={() => handleImageData(row)} src={imagePng} sx={{ width:"2rem", borderRadius:'1rem', cursor:'pointer' }} /> 
              :
               (
                row[header]
              )}

        {/* { header === 'image_url' ? (
                // <MailOutlineIcon sx={{ cursor:'pointer' }} onClick={() => handleMailData(row)  }  />
                <Box component-="img" src={imagePng} sx={{ width:"2rem" }} />
                // <a href={row[header]} target="_blank" rel="noopener noreferrer">
                //   {row[header]}
                // </a>
              ) : (
                row[header]
              )} */}

               {/* || header === 'image_url' */}
            </TableCell>
          ))}
        </TableRow>
      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={reportData?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  // onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Box sx={{ gap:1, padding:1, display:'flex', alignItems:"center", justifyContent:'right' }} >
              {/* <Button onClick={() => generatePDF()}  sx={{ fontFamily:'montserrat', fontWeight:500 }} variant='outlined' size='small' endIcon={<DownloadIcon/>} >PDF</Button> */}
              <Button onClick={() => generateExcel()} sx={{ fontFamily:'montserrat', fontWeight:500 }} variant='outlined' size='small' endIcon={<DownloadIcon/>}  >download EXCEL</Button>
            </Box>
                </TableContainer> : null
            }

        </Box>



             

        <SnackBarSuccess open={snackOpen} message={snackMessage} snackClose={handelSnackClose} status={snackSeverity} />
        
    
    </Box>
)
}

export default Report