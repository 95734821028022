import { Box, Button, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../components/Header';
import DownloadIcon from '@mui/icons-material/Download';


const ViewCard = () => {

    const location = useLocation();
    const navigate = useNavigate('');
    
    const locationData = location.state.data;
    const jsonTableData = locationData.card_details;
    const cardName = locationData.bank
    console.log(jsonTableData);
    console.log(locationData);

    // otherPartyName

  return (
   <Box className="topupContainer" >
      <Header/>
      <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly' }} >


      <TableContainer elevation={16} component={Paper} sx={{ marginTop:1.5, width:"90%" }} >
                    <Table sx={{ minWidth: 650 ,backgroundColor:'white' }} aria-label="simple table">
                        <TableHead>
                        <TableRow sx={{ backgroundColor:'#219bff' }} >
                            <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:"1rem" }} align="center"  >Sr. No</TableCell>
                            <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:"1rem" }} align="center"  >Amount</TableCell>
                            <TableCell  sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:"1rem" }}  align="center">Balance</TableCell>
                            <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:"1rem" }}   align="center">Time</TableCell>
                            <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:"1rem" }}   align="center">Type</TableCell>
                            <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:"1rem" }}   align="center">Platform</TableCell>
                            <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:"1rem" }}   align="center">Bank ID</TableCell>
                            <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:"1rem" }}   align="center">Txn Ref</TableCell>
                            <TableCell  sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:"1rem" }}  align="center">Status</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                        Array.isArray(locationData) && locationData?.map((row, index) => (
                                <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                    <TableCell align="center" sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >{ index + 1}</TableCell>
                                    <TableCell align="center" sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem" , color:"#03136b"   }}  >₹{row.transaction.amount}</TableCell>
                                    <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >₹{ row.transaction.balance }</TableCell>
                                    <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >{new Date(row.transaction.time).toDateString()  }</TableCell>
                                    <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >{row.transaction.type}</TableCell>
                                    <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >{row.transaction.otherPartyName}</TableCell>
                                    <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >{row.transaction.bankTid}</TableCell>
                                    <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >{row.transaction.txRef}</TableCell>
                                    <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.95rem"  }}  >{row.transaction.transactionStatus}</TableCell>

                                </TableRow>
                            ))
                        }
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* <Pagination
                style={paginationStyle}
                variant="outlined"
                color="primary"
                count={Math.ceil(tableData?.length / 8)}
                page={page}
                onChange={handleChangePage}
            />
         */}
      </Box>
    </Box>
  )
}

export default ViewCard