import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../components/Header';
import { Alert, Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, OutlinedInput, Paper, Snackbar, TextField, Typography } from '@mui/material';
import card from "../images/activatingCard.png"
import OTPInput from 'react-otp-input';
import Cookies from 'js-cookie';
import env from 'react-dotenv';

const ActivatingCard = () => {

  const [submitDialog, setSubmitDialog] = React.useState(false);
  const [otp , setOtp] = React.useState('');
  const [activateOpen , setActiveOpen] = React.useState(false);
  const [activateSOpen , setActiveSOpen] = React.useState(false);
  const [jsonError , setJsonError] = React.useState('');
  const [otpId , setOtpID] = React.useState('');
  const [jsonSError , setJsonSError] = React.useState('');
  const [ progress , setProgress ] = React.useState(false);
  

  const [name , setName] = React.useState('');
  const [ email , setEmail ] = React.useState('');
  const [ address , setAddress ] = React.useState("");
  const [ dob , setDob ] = React.useState("");
  const [ id , setId ] = React.useState("");
  const [ category ,setCategory ] = React.useState('');

  const [ sessionId , setSessionId ] = React.useState("");

  const location = useLocation();
  const navigate = useNavigate();

React.useEffect(() => {
  try{
    const locationData = location.state.data;
    const name = locationData.name;
    setName(name);
  
    const email = locationData.email_id;
    setEmail(email);
  
    const address = locationData.per_add
    setAddress(address);
  
    const dob = locationData.dob;
    setDob(dob);
  
    const id = locationData.id;
    console.log("id : ",id)
    setId(id);

    const category = locationData.category_name;
    setCategory(category);
    console.log("category name : ",category);
  
    console.log("location data : ",locationData);
  }catch(err){
    navigate('/')
    window.alert("url cannot be accessed directly..");
  }

},[])

    const handleClose = () => {
      setSubmitDialog(false);
    };

    const handleActiveCardClose = () => {
        setActiveOpen(false);
    }

    const handleActiveSCardClose = () => {
      setActiveSOpen(false);
  }

    const authAccess = Cookies.get('demoCAuth');
    console.log("token :",authAccess);


    async function handleOtp(){

      setProgress(true)

      const url = env.REACT_APP_UAPI_URL

      const data = await fetch(`${url}/api/generateOtp`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          "Authorization":`Bearer ${authAccess}`
        },
        body:JSON.stringify({ "id":id })
      });
      const json = await data.json();
      console.log("json otp : ",json);

      if(json){
        if(json.Status === "Error"){
            setActiveOpen(true);
            setJsonError(json.Message)
            setProgress(false)
        }
       if(json.Status === "Success"){
          setOtpID(json.Data)
          setSubmitDialog(true);
          setProgress(false)
        }
      }
    }

    const verifyOtp = async() => {
      try{

        const url = env.REACT_APP_UAPI_URL

        const fetchData = await fetch(`${url}/api/TransGprRegistration`, {
         method:"POST",
        headers:{
          "Content-Type":"application/json",
            "Authorization":`Bearer ${authAccess}`
        },
        body:JSON.stringify({ "id":otpId })
        });
  
        const json = await fetchData.json();
        console.log("json data : ",json)
  
        if(json){
          if(json.Status === "Error"){
            setActiveOpen(true);
            setJsonError(json.Data)
          }
          if(json.Status === "Success"){
            setActiveSOpen(true)
            setJsonSError(json.Data);
          }
        }
      }catch(err){
        console.log(err)
      }
    }

    const submitOtp = async() => {
      try{

        const url = env.REACT_APP_UAPI_URL

        setProgress(true);

        const fetchData = await fetch(`${url}/api/FinoSendOTP`, {
          method:"POST",
          headers:{
            "Content-Type":"application/json",
            "Authorization":`Bearer ${authAccess}`
          },
          body:JSON.stringify({ "id":id })
        });

        const json = await fetchData.json();
        setProgress(false)
        console.log("json",json);

        if(json){
          if(json.Status === "Success"){
            console.log("session id : ", json.Message.sessionId )
            setSessionId( json.Message.sessionId )
            setSubmitDialog(true)
          }
          if(json.Status === "Error"){
            setActiveOpen(true);
            setJsonError(json.Message)
          }
        }
        
      }catch(err){
        console.log(err);
      }
    }

    const costumerMinKyc = async() => {

      const url = env.REACT_APP_UAPI_URL

      const fetchData = await fetch(`${url}/api/FinocustomerMinKyc`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          "Authorization":`Bearer ${authAccess}`
        },
        body:JSON.stringify({ "otp":otp , "sessionId":sessionId , "id":id })
      });
      console.log({ "otp":otp , "sessionId":sessionId , "id":id })
      const json = await fetchData.json();
      console.log("min kyc data : ",json)

      if(json){
        if(json.Status === "Success"){
          console.log("json success : ",json.Message);
          setActiveSOpen(true)
          setJsonSError(json.Message);
          setTimeout(() => {
            navigate(-1)
          },2500)
        }
        if(json.Status === "Error"){
          console.log("json error : ",json.Message);
          setActiveOpen(true);
          setJsonError(json.Message);
          setTimeout(() => {
            navigate(-1)
          },2500)
        }
      }
    }


  

  return (
    <Box className="topupContainer"  >
      <Header/>
      <Box sx={{ display:'flex', alignItems:'center', justifyContent:"space-evenly", mt:5 }} >

        <Box component='img' src={card} sx={{ width:"40rem" }} />

        <Paper elevation={16} sx={{ padding:"2rem 2rem", borderRadius:3 }} >
          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column", gap:2.5 }} >
            <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:"1.5rem", marginBottom:2 }} >Verify User Details</Typography>

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"row", gap:2 }} >

            <TextField InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={name}  label='name' />
            <TextField InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={ dob } label='DOB' />

            </Box>

            <TextField InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={email} fullWidth label='email' />
            <TextField InputProps={{ style:{ fontFamily:'montserrat', fontWeight:500 } }} value={address} fullWidth label='address' />
            {
              category === "Fino" ? progress ? <CircularProgress/> :  <Button onClick={() => submitOtp()} fullWidth variant='contained' sx={{ fontFamily:'montserrat', fontWeight:500 }} >submit fino</Button> : 
          <Button onClick={() => handleOtp()} fullWidth variant='contained' sx={{ fontFamily:'montserrat', fontWeight:500 }} >Submit transcop</Button>
            }
          </Box>
        </Paper>
      </Box>


      <Dialog
        open={submitDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ fontFamily:'montserrat', fontFamily:500 }} id="alert-dialog-title">
          {"Enter OTP"}
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:"0.9rem", color:'gray', textTransform:"capitalize" }} >OTP has been sent to your registred mobile number</Typography>
          <TextField value={otp} onChange={(e) => setOtp(e.target.value)} size='medium' fullWidth label="One Time Password" sx={{ mt:2 }} ></TextField>
          <Button variant='contained' onClick={() => costumerMinKyc()} sx={{ fontFamily:'montserrat', fontWeight:500, mt:2 }} fullWidth >submit</Button>
        </DialogContent>
        
      </Dialog>

      <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"right" }}
          open={activateOpen}
          onClose={handleActiveCardClose}
          autoHideDuration={2500}
          >
            <Alert severity='error' >{jsonError}..</Alert>
          </Snackbar>

          <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"right" }}
          open={activateSOpen}
          onClose={handleActiveSCardClose}
          autoHideDuration={2500}
          >
            <Alert severity='success' >{jsonSError}..</Alert>
          </Snackbar>

    </Box>
  )
}

export default ActivatingCard