import { Box, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
      <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', backgroundColor:"#9599bf", p:0.5 , width:"100%", position:'fixed', bottom:0, zIndex:1000  }} >
      <Typography sx={{ fontFamily:"montserrat", fontWeight:400, color:'white', marginLeft:2, fontSize:{xs:"0.6rem", sm:"1rem", md:"1rem", lg:"1rem"}, textTransform:'capitalize' }} >© 2021 Copyright. All rights reserved.</Typography>
      <Typography sx={{ fontFamily:"montserrat", fontWeight:400, color:'white', marginRight:5, fontSize:{xs:"0.6rem", sm:"1rem", md:"1rem", lg:"1rem"} }} >Design by <span onClick={() => window.open(`${process.env.REACT_APP_UAT_URL}`) } style={{ color:"#32029c", cursor:"pointer" }} > Eezib</span> </Typography>
      </Box>
  )
}

export default Footer