import { Alert, Snackbar } from '@mui/material'
import React from 'react'

const SnackBarSuccess = ({message, status, open, snackClose }) => {

  console.log("snackbar called");

    const [activateSOpen , setActiveSOpen] = React.useState(open);

    const handleActiveSCardClose = () => {
        setActiveSOpen(false);
    }


  return (
    <Snackbar
    anchorOrigin={{ vertical:"top", horizontal:"right" }}
    open={open}
    onClose={snackClose}
    autoHideDuration={2500} >

      <Alert severity={status} sx={{ width:'100%' }} >{message}..</Alert>

    </Snackbar>
  )
}

export default SnackBarSuccess